<template>
    <div>
        <Listbox as="div" v-model="language">
            <div class="relative sm:mt-2">
                <ListboxButton class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-12 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-red-500 sm:text-sm sm:leading-6">
                  <div v-if="language == 'fr-FR'" class="flex items-center">
                      <img src="/assets/france-flag.webp" alt="" class="h-5 w-5 flex-shrink-0 rounded-full" />
                      <span class="ml-3 block truncate">Français</span>
                  </div>
                  <div v-if="language == 'en-US'" class="flex items-center">
                      <img src="/assets/usa-flag.webp" alt="" class="h-5 w-5 flex-shrink-0 rounded-full" />
                      <span class="ml-3 block truncate">English</span>
                  </div>
                  <div v-if="language == 'it-IT'" class="flex items-center">
                      <img src="/assets/italy-flag.webp" alt="" class="h-5 w-5 flex-shrink-0 rounded-full" />
                      <span class="ml-3 block truncate">Italian</span>
                  </div>
                  <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                    <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <ListboxOption as="template" v-for="item in locales" :key="typeof item == 'object' ? item.code : item" :value="typeof item == 'object' ? item.code : item" v-slot="{ active, selected }">
                            <li :class="[active ? 'bg-red-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-8']">
                                <div class="flex items-center">
                                    <div v-if="item.name == 'Français'">
                                        <img src="/assets/france-flag.webp" alt="" class="h-5 w-5 flex-shrink-0 rounded-full" />
                                    </div>
                                    <div v-if="item.name == 'English'">
                                        <img src="/assets/usa-flag.webp" alt="" class="h-5 w-5 flex-shrink-0 rounded-full" />
                                    </div>
                                    <div v-if="item.name == 'Italian'">
                                        <img src="/assets/italy-flag.webp" alt="" class="h-5 w-5 flex-shrink-0 rounded-full" />
                                    </div>
                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{ typeof item == 'object' ? item.name : item }}</span>
                                </div>
                                <span v-if="selected" :class="[active ? 'text-white' : 'text-red-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                </span>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import { useI18n } from 'vue-i18n'

const { locales, locale, setLocale } = useI18n()

const language = computed<string>({
    get: () => locale.value,
    set: (value: string) =>
        setLocale(value)
            .then(() => {
                console.log('Language changed to: ' + value)
            })
            .catch((err: Error) => {
                console.error(err)
            }),
    })
</script>
